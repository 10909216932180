<template>
  <ion-page>
    <ion-header>
      <ion-toolbar class="px-2">
        <ion-label slot="start" class="fs-3 fw-bold">{{ $t('companies') }}</ion-label>
        <ion-icon class="close-modal" slot="end" :icon="closeOutline" @click="closeModal"></ion-icon>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="isLoading" class="px-2">
      <ion-item v-for="(item, index) in 4" :key="index" lines="full" :detail="false" class="skeleton mt-3">
        <ion-thumbnail slot="start">
          <ion-skeleton-text class="avatar" :animated="true"></ion-skeleton-text>
        </ion-thumbnail>
        <ion-label>
          <h3>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 40%; height: 18px"
            ></ion-skeleton-text>
          </h3>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 35%; height: 30px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 100%; height: 35px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 50%; height: 20px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-item>
    </ion-content>
    <ion-content v-else style="--background: #eeeeee">
      <ion-list v-if="customer" class="pt-2 mb-4">
        <ion-radio-group
          :allow-empty-selection="false"
          :value="selectedCompany?.id || previousSelectedCompanyId"
          mode="md"
        >
          <ion-text class="pl-3 name text-primary">{{ $t('selectCompany.main_company') }}</ion-text>
          <ion-item
            class="card-company mt-2 ion-no-padding mb-3"
            style="margin-right: -4vw"
            lines="full"
            @click="onTapCustomer(customer)"
            detail="false"
          >
            <ion-radio slot="end" :value="customer.id" @ionFocus="onTapCustomer(customer)"></ion-radio>
            <ion-row class="ion-align-items-start ml-2">
              <ion-col size="auto">
                <div class="image-default" :style="`background: ${theme.background}; color:${theme.color}`">
                  {{ getInitialName(customer?.name?.trim() || customer?.branch?.trim()) }}
                </div>
              </ion-col>
              <ion-col>
                <ion-row>
                  <ion-text class="fw-700 fs-2">
                    {{ customer.name || customer.branch }}
                  </ion-text>
                </ion-row>
                <ion-row class="ion-align-items-center w-100">
                  <ion-text class="fw-500 fs-2 pr-1 py-1" color="primary">{{
                    customer.account_number || customer.customer.account_number
                  }}</ion-text>
                  <ion-chip class="b2c-label-chip" v-if="customer.is_b2c">B2C</ion-chip>
                  <ion-col v-if="customer?.black_list" size="auto" style="margin-right: -5px">
                    <div class="box-label d-flex align-center">
                      <ion-icon class="fs-2" :icon="banOutline" color="danger"></ion-icon>
                      <span class="text-danger ml-1 fs-1 fw-500">{{ $t('black_listed') }}</span>
                    </div>
                  </ion-col>
                  <ion-col v-if="customer?.has_credit_block" size="auto">
                    <div class="box-label d-flex align-center credit-block">
                      <span class="text-white fs-1">{{ $t('credit_block') }}</span>
                    </div>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-text class="fs-2">{{ customer.address || $t('not_provided_address') }}</ion-text>
                </ion-row>
              </ion-col>
            </ion-row>
          </ion-item>
          <div v-if="customer.branches?.length > 0" class="space-grey"></div>
          <div class="pl-3 name text-primary mt-3" v-if="customer.branches?.length > 0">
            {{ $t('selectCompany.branches') }}
          </div>
          <div v-for="company in customer.branches" :key="company.id">
            <ion-item
              lines="full"
              class="card-company mt-2 ion-no-padding mb-3"
              style="margin-right: -4vw"
              @click="onTapCustomer(company)"
              detail="false"
            >
              <ion-radio slot="end" :value="company.id" @ionFocus="onTapCustomer(company)"></ion-radio>
              <ion-row class="ion-align-items-start ml-2">
                <ion-col size="auto">
                  <div
                    class="image-default"
                    :style="`background: ${themeBranch.background}; color:${themeBranch.color}`"
                  >
                    {{ getInitialName(company?.name?.trim() || company?.branch?.trim()) }}
                  </div>
                </ion-col>
                <ion-col>
                  <ion-row>
                    <ion-text class="fw-700 fs-2">
                      {{ company.name || company.branch }}
                    </ion-text>
                  </ion-row>
                  <ion-row class="ion-align-items-center py-1 w-100">
                    <ion-text class="fw-500 fs-2 pr-1" color="primary">{{
                      company.account_number || company.customer.account_number
                    }}</ion-text>
                    <ion-col v-if="company?.black_list" size="auto" style="margin-right: -5px">
                      <div class="box-label d-flex align-center">
                        <ion-icon class="fs-2" :icon="banOutline" color="danger"></ion-icon>
                        <span class="text-danger ml-1 fs-1 fw-500">{{ $t('black_listed') }}</span>
                      </div>
                    </ion-col>
                    <ion-col v-if="company?.has_credit_block" size="auto">
                      <div class="box-label d-flex align-center credit-block">
                        <span class="text-white fs-1">{{ $t('credit_block') }}</span>
                      </div>
                    </ion-col>
                  </ion-row>
                  <ion-row>
                    <ion-text class="fs-2">{{ company.address || $t('not_provided_address') }}</ion-text>
                  </ion-row>
                </ion-col>
              </ion-row>
            </ion-item>
          </div>
        </ion-radio-group>
      </ion-list>
    </ion-content>
    <ion-footer>
      <ion-toolbar v-if="isLoading" class="my-3 px-2" style="--background: white">
        <ion-label>
          <p>
            <ion-skeleton-text
              class="skeleton-child mb-2"
              :animated="true"
              style="width: 100%; height: 40px"
            ></ion-skeleton-text>
          </p>
          <p>
            <ion-skeleton-text
              class="skeleton-child"
              :animated="true"
              style="width: 100%; height: 40px"
            ></ion-skeleton-text>
          </p>
        </ion-label>
      </ion-toolbar>
      <ion-toolbar v-else class="mb-3 px-2" style="--background: transparent">
        <ion-button
          :disabled="disableApplyButton"
          class="ion-margin-top foot-btn"
          expand="block"
          @click="applySelectedBranch"
          >{{ $t('apply') }}</ion-button
        >
        <ion-button
          class="ion-margin-top foot-btn-outline"
          expand="block"
          fill="outline"
          @click="changeCustomer"
          >{{ $t('select_other_company') }}</ion-button
        >
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { ACTIONS as ACTIONS_ADDRESS } from '@/modules/sale/store/address/actions';
import { ACTIONS } from '@/modules/sale/store/customer/actions';
import { ACTIONS as ACTIONS_PAYMENT } from '@/modules/sale/store/payment/actions';
import { handleRandomBackground } from '@/modules/shared/utils';
import { setBadgeNumber } from '@/modules/shared/utils/badge';
import { STORAGE_KEYS } from '@/storage';
import { banOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, inject, ref } from 'vue';
import { createNamespacedHelpers, useStore } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('sale/customer');
const { mapActions: mapActionsPayment } = createNamespacedHelpers('sale/payment');
const { mapActions: mapActionsAddress } = createNamespacedHelpers('sale/address');
export default defineComponent({
  name: 'branch-selection',
  emits: ['closeModal', 'resetPath', 'resetSearch', 'refreshData'],
  props: {
    customerSelect: {
      type: Object,
      default: null
    },
    showChangeBtn: {
      type: Boolean,
      default: false
    }
  },
  inject: ['$storage'],
  setup() {
    const store = useStore();
    const ionRouter = inject('navManager');
    const ROUTE_PATH = {
      INVOICE: '/sale/invoices/select-invoices',
      CART: '/sale/cart',
      CUSTOMER: '/sale/select-customer',
      HOME: '/sale/main/home'
    };
    return {
      ionRouter,
      closeOutline,
      banOutline,
      store,
      showChangeButton: ref(true),
      previousSelectedCompanyId: ref(null),
      selectedCompany: ref(null),
      customer: ref(null),
      isLoading: ref(false),
      theme: ref([]),
      themeBranch: ref([]),
      selectedBranch: ref(null),
      currentPage: ref(null),
      prevPage: ref(null),
      ROUTE_PATH
    };
  },
  async created() {
    this.theme = handleRandomBackground();
    this.themeBranch = handleRandomBackground();
  },
  async mounted() {
    this.currentPage = this.$route.path;
    this.prevPage = window.history?.state?.back;
    this.isLoading = true;
    const customer = await this.$storage.getSelectCustomer();
    const companyData = await this.$storage.getSelectedCompany();
    const { tenant } = await this.$storage.getUser();
    this.selectedCompany = companyData;
    this.previousSelectedCompanyId = companyData ? companyData.id : null;
    await this[ACTIONS.GET_CUSTOMERS_BRANCH]({
      id: customer.id,
      tagged: true,
      tenant_id: tenant.id
    });
    if (await this.isThereUpdatedBranch()) {
      await this.$storage.setSelectedCompany({ ...this.customerBranch.data[0] });
      const companyBranchUpdate = await this.$storage.getSelectedCompany();
      this.customer = companyBranchUpdate;
    } else {
      this.customer = this.customerSelect ? this.customerSelect : this.customerBranch.data[0];
    }
    this.isLoading = false;
    this.showChangeButton = this.showChangeBtn
      ? this.showChangeBtn
      : !this.$route.path.endsWith(this.ROUTE_PATH.CUSTOMER);
    const isIncluded =
      this.customer?.branches?.length > 0 && this.selectedCompany?.id !== this.customer?.id
        ? this.customer?.branches.some((branch) => branch.id === this.selectedCompany?.id)
        : this.selectedCompany?.id === this.customer?.id
        ? true
        : false;
    if (isIncluded) {
      this.onTapCustomer(this.selectedCompany);
    }
  },
  computed: {
    ...mapGetters(['customerBranch']),
    disableApplyButton() {
      let checkBranchSelected = false;
      let checkSelectedMain = this.selectedCompany?.id === this.customer?.id ? true : false;
      if (this.customerBranch?.data[0]?.branches?.length > 0 && !checkSelectedMain) {
        checkBranchSelected = this.customer?.branches.some(
          (branch) => branch.id === this.selectedCompany?.id
        );
      } else {
        checkBranchSelected = true;
      }
      return !checkBranchSelected && this.selectedBranch === null;
    }
  },
  methods: {
    ...mapActions([ACTIONS.UPDATE_SELECTED_CUSTOMER, ACTIONS.GET_CUSTOMERS_BRANCH]),
    ...mapActionsPayment([ACTIONS_PAYMENT.REMOVE_SELECTED_PAYMENT_INVOICES]),
    ...mapActionsAddress([ACTIONS_ADDRESS.GET_ADDRESSES]),
    async setBadgeNumber(customerId) {
      await setBadgeNumber(this.store, customerId);
    },
    getInitialName(name) {
      if (!name) return ``;
      return name
        .split(' ')
        .slice(0, 1)
        .filter(Boolean)
        .map((word) => word[0].toUpperCase())
        .join('');
    },
    async isThereUpdatedBranch() {
      const currentCompany = await this.$storage.getSelectedCompany();
      const listBranches = this.customerBranch?.data[0]?.branches ?? [];
      const isChildBranchSelected = !currentCompany?.branches;
      if (!isChildBranchSelected) {
        if (listBranches.length !== currentCompany.branches.length) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    },
    async closeModal() {
      await this.$storage.setBackChangeCompany(false);
      this.$emit('closeModal');
    },
    setOpen(params) {
      this.isLoading = params;
    },
    onTapCustomer(value) {
      this.selectedBranch = value;
    },
    async updateAddress() {
      let company = await this.$storage.getSelectedCompany();
      await this[ACTIONS_ADDRESS.GET_ADDRESSES]({
        supplierId: company.id,
        active: true
      });
    },
    async applySelectedBranch() {
      this.setBadgeNumber(this.selectedBranch?.id);
      if (this.previousSelectedCompanyId && this.previousSelectedCompanyId != this.selectedBranch?.id) {
        await this.updateCompanyInfo(this.selectedBranch);
      } else {
        const selectedCompany = this.selectedCompany ? { ...this.selectedCompany } : { ...this.customer };
        selectedCompany.branches = [];
        await this.updateCompanyInfo(this.selectedBranch);
      }
      if (this.customer?.branches?.length > 0 ?? false) {
        this.$emit('resetPath');
      }
      await this.updateAddress();
      this.$emit('resetSearch');
      this.$emit('closeModal');
      this.$emit('refreshData');
      this.ionRouter.navigate({
        routerLink:
          this.currentPage.endsWith(this.ROUTE_PATH.CART) ||
          (this.currentPage.endsWith(this.ROUTE_PATH.CUSTOMER) &&
            this.prevPage.endsWith(this.ROUTE_PATH.CART))
            ? this.ROUTE_PATH.CART
            : this.currentPage.endsWith(this.ROUTE_PATH.INVOICE) ||
              this.prevPage.endsWith(this.ROUTE_PATH.INVOICE)
            ? this.ROUTE_PATH.INVOICE
            : this.ROUTE_PATH.HOME
      });
    },
    async clearAddress() {
      await this.$storage.remove(STORAGE_KEYS.SELECTED_DELIVERY_ADDRESS);
      await this.$storage.remove(STORAGE_KEYS.SELECTED_BILLING_ADDRESS);
    },
    async updateCompanyInfo(value) {
      await this[ACTIONS.UPDATE_SELECTED_CUSTOMER](value);
      await this.clearAddress();
      await this.$storage.setSelectedCompany(value);
      await this.$storage.setSelectedCustomer(this.customer);
    },
    async changeCustomer() {
      this.$emit('closeModal');
      await this[ACTIONS_PAYMENT.REMOVE_SELECTED_PAYMENT_INVOICES]();
      await this.clearAddress();
      await this.$storage.setBackChangeCompany(false);
      this.ionRouter.navigate({
        routerLink: this.ROUTE_PATH.CUSTOMER
      });
    }
  }
});
</script>

<style scoped lang="scss">
.name {
  font-size: 13px;
  font-weight: 700;
  line-height: 14px;
  text-transform: uppercase;
}
ion-label {
  :not(.b2c-label-chip) {
    white-space: normal !important;
    font-weight: 400;
    line-height: 22px;
  }
}
#row {
  height: 50px;
  font-size: 20px;
}
.close-modal {
  font-size: 20px;
  color: #92949c;
  background: #989aa226;
  padding: 5px;
  border-radius: 20px;
}
.avatar {
  --border-radius: 9999px;
}
.skeleton-child {
  --border-radius: 8px;
}
.skeleton {
  align-content: flex-start;
  align-items: flex-start;
  text-align: left;
}
.image-default {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 14.4px;
  line-height: 17px;
}
.card-company {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.space-grey {
  height: 8px;
  width: 100vw;
  background: #eeeeee;
}
.foot-btn-outline {
  --border-radius: 10px;
  --color: #00676a;
}
.foot-btn {
  --border-radius: 10px;
  --background: #00676a;
  --color: white;
}
.foot-btn[disabled] {
  --border-radius: 10px;
  --background: #9e9e9e;
  --color: white;
}
.box-label {
  background-color: var(--ion-color-text-error-100);
  width: fit-content;
  padding: 3px 7px;
  border-radius: 4px;
  &.credit-block {
    background-color: #f15c51;
  }
}
.shadow-bottom {
  box-shadow: 0px 1px 1px 0px rgb(171, 171, 171);
}
</style>
